export default class NavTabs extends HTMLElement {
    constructor() {
      super();
      
      this.elements = {
        list: this.dataset.tablist || '.nav-tabs',
        tabs: this.dataset.tabs || '.nav-tab',
        content: this.dataset.content || '.tab-content'
      };

      

      this.state = {};
      this.tablist = this.querySelector(this.elements.list);
      this.tabs = this.tablist.querySelectorAll(this.elements.tabs);
      this.content = this.querySelectorAll(this.elements.content);
      this.setup = this.setup.bind(this);
  
      this.setup();
  
      this.parentElement.addEventListener("shopify:section:unload", () => {
        this.teardown();
      });
      this.parentElement.addEventListener("shopify:section:load", () => {
        this.setup();
      });
      this.parentElement.addEventListener("shopify:block:select", (event) => {
        console.log({
          event,
          index: [...event.target.parentElement.children].indexOf(event.target),
        });
      });
    }
  
    changeTab(event) {
      const tab = event.target.closest(this.elements.tabs);
      const tabId = tab.dataset.tab;
  
      if (tab === this.state.activeTab) return;
  
      this.content.forEach((cnt) => {
        if (cnt.dataset.tab === tabId) {
          cnt.classList.toggle("active");
          tab.classList.toggle("active");
          this.state.activeContent.classList.toggle("active");
          this.state.activeTab.classList.toggle("active");
          this.state.activeTab = tab;
          this.state.activeContent = cnt;
        }
      });
    }
  
    setup() {
      this.tabs.forEach((tab) => {
        if (tab.classList.contains("active")) {
          this.state.activeTab = tab;
        }
        tab.addEventListener("click", this.changeTab.bind(this));
      });
  
      this.state.activeContent = this.querySelector(`${this.elements.content}.active`);
    }
  
    teardown() {
      this.tabs.forEach((tab) => {
        tab.removeEventListener("click", this.changeTab.bind(this));
      });
    }
  }
  

  